<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="createUser">
      <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2">
              {{ $t("CrearSubClient") }}
            </h4>
          </b-col>
          <b-col cols="6" class="mb-2 auth-create-form">
            <ImageDropzone ref="images" :files="files" max-files="1" :label="'AddImageSubClient'" />
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Nombre')" rules="required">
              <b-form-group :label="$t('Nombre')" label-for="account-name">
                <b-form-input v-model="name" name="name" :placeholder="$t('Nombre')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-form-group :label="$t('LegalName')" label-for="account-legal_name">
              <b-form-input v-model="legal_name" :placeholder="$t('LegalName')" name="legal_name" />
            </b-form-group>
            <validation-provider #default="{ errors }" :name="$t('CIF')">
              <b-form-group :label="$t('CIF')" label-for="account-cif">
                <b-form-input v-model="cif" name="cif" :placeholder="$t('CIF')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Telefono')" rules="max:9|min:9">
              <b-form-group :label="$t('Telefono')" label-for="account-phone">
                <b-input-group>
                  <template #prepend>
                    <vue-country-code @onSelect="onSelect" :preferredCountries="['es']">
                    </vue-country-code>
                  </template>

                  <b-form-input type="number" v-model="phone" name="company" :placeholder="$t('Telefono')" />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Email')" rules="email|emailUnique">
              <b-form-group :label="$t('Email')" label-for="account-e-mail">
                <b-form-input v-model="email" type="email" name="email" :placeholder="$t('Email')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Web')">
              <b-form-group :label="$t('Web')" label-for="client-web">
                <b-form-input v-model="web" name="reference" :placeholder="$t('Web')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col sm="6">
            <b-form-group :label="$t('Province')" label-for="province">
              <BaseSelect :resource="'/provinces'" @input="
        [(province = $event), (town = ''), (townsKey = new Date())]
        " :value="province" :label="'Provincia'" :clearable="!town" />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group :label="$t('Towns')" label-for="town">
              <BaseSelect :resource="'/towns'" @input="town = $event" :value="town"
                :httpBody="{ province_id: province.id }" :label="'Municipio'" :readonly="!province" :key="`${townsKey}`"
                :clearable="true" />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('Direccion')">
              <b-form-group :label="$t('Direccion')" label-for="client-address">
                <b-form-input v-model="address" name="reference" :placeholder="$t('Direccion')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="6">
            <validation-provider #default="{ errors }" :name="$t('CodigoPostal')">
              <b-form-group :label="$t('CodigoPostal')" label-for="client-postalCode">
                <b-form-input v-model="postalCode" name="reference" :placeholder="$t('CodigoPostal')" />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12 text-right">
            <b-button type="submit" variant="primary" class="mt-2 mr-1">
              {{ $t("Guardar") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import VueCountryCode from "vue-country-code-select";
import BaseSelect from "@/components/ui/select/BaseSelect.vue";

export default {
  components: {
    ImageDropzone,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BaseSelect,
    VueCountryCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      townsKey: new Date(),
      AngleIcon: require("@/assets/images/icons/angle.svg"),
      currentPage: 1,
      pageLength: 10,
      roles: "",
      companies: "",
      clients: [],

      files: [],
      profileFile: null,
      required,

      userPrepare: false,
      disabledClients: true,
      cif: "",
      name: "",
      legal_name: "",
      dni: "",
      email: "",
      phone: "",
      codePhone: "es",
      codePhoneSave: JSON.stringify({
        iso: "ES",
        code: 34,
      }),
      avatar: "",
      web: "",
      postalCode: "",
      address: "",

      province: "",
      town: "",
    };
  },
  computed: {
    ...mapGetters({
      currentClient: "clients/getCurrentClient",
      selectCompanies: "companies/getListCompanies",

      selectClients: "clients/getSelectClients",
      departmentsSelect: "departments/getSelectDepartments",
      currentRole: "auth/getRole",
      currentCompany: "companies/getCurrentCompany",
    }),
  },
  watch: {
    companies() {
      this.clients = [];

      if (this.companies) {
        this.searchClients({
          company: this.companies.id,
          client: this.currentClient,
        });
        this.disabledClients = false;
      } else {
        this.disabledClients = true;
      }
    },
    clients() {
      this.departments = [];

      const aux = this;
      if (this.clients.length > 0) {
        aux.selectDepartments({ clients: this.clients });
      } else {
        this.setSelectDepartments([]);
      }
    },
  },
  methods: {
    ...mapActions({
      create: "subclients/create",
      searchCompanies: "companies/searchCompanies",
      searchClients: "clients/selectClients",
      selectDepartments: "departments/selectDepartments",
      getRoles: "users/getRoles",
    }),
    ...mapMutations({
      setSelectDepartments: "departments/setSelectDepartments",
    }),
    setClients() {
      if (this.currentRole == "admin_cliente") {
        this.clients = this.currentClient;
        // this.selectDepartments({ clients: this.clients });
      }
    },
    handleSubmit() {
      this.$refs.createUser.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ user: formData });
          }
        }
      });
    },
    createFormData() {
      const data = this.$refs.images.getFormData();

      data.append("name", this.name);
      data.append("legal_name", this.legal_name);
      data.append("cif", this.cif);

      data.append("email", this.email);
      data.append("phone", this.phone);
      data.append("code_phone", this.codePhoneSave);
      data.append("web", this.web);

      if (this.currentRole == "admin_cliente") {
        data.append("companies[]", this.currentCompany.id);
        data.append("client_id", this.currentClient.id);
      } else {
        if (this.companies) {
          data.append("companies[]", this.companies.id);
        }
        data.append("client_id", this.clients.id);
      }

      if (this.province.id) {
        data.append("province_id", this.province.id);
      }
      if (this.town.id) {
        data.append("town_id", this.town.id);
      }

      data.append("address", this.address);
      data.append("postalCode", this.postalCode);

      return data;
    },
    onSelect({ name, iso2, dialCode }) {
      this.codePhone = dialCode;
      this.codePhoneSave = JSON.stringify({
        iso: iso2,
        code: dialCode,
      });
    },
  },
  async created() {
    await this.searchCompanies();
    await this.setClients();
    await this.getRoles();
  },
  destroyed() {
    this.$store.commit("users/setUser", {});
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
