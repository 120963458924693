var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"createUser"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("CrearSubClient"))+" ")])]),_c('b-col',{staticClass:"mb-2 auth-create-form",attrs:{"cols":"6"}},[_c('ImageDropzone',{ref:"images",attrs:{"files":_vm.files,"max-files":"1","label":'AddImageSubClient'}})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"account-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-form-group',{attrs:{"label":_vm.$t('LegalName'),"label-for":"account-legal_name"}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('LegalName'),"name":"legal_name"},model:{value:(_vm.legal_name),callback:function ($$v) {_vm.legal_name=$$v},expression:"legal_name"}})],1),_c('validation-provider',{attrs:{"name":_vm.$t('CIF')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CIF'),"label-for":"account-cif"}},[_c('b-form-input',{attrs:{"name":"cif","placeholder":_vm.$t('CIF')},model:{value:(_vm.cif),callback:function ($$v) {_vm.cif=$$v},expression:"cif"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Telefono'),"rules":"max:9|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telefono'),"label-for":"account-phone"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('vue-country-code',{attrs:{"preferredCountries":['es']},on:{"onSelect":_vm.onSelect}})]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"number","name":"company","placeholder":_vm.$t('Telefono')},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"email|emailUnique"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"account-e-mail"}},[_c('b-form-input',{attrs:{"type":"email","name":"email","placeholder":_vm.$t('Email')},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Web')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Web'),"label-for":"client-web"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Web')},model:{value:(_vm.web),callback:function ($$v) {_vm.web=$$v},expression:"web"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Province'),"label-for":"province"}},[_c('BaseSelect',{attrs:{"resource":'/provinces',"value":_vm.province,"label":'Provincia',"clearable":!_vm.town},on:{"input":function($event){[(_vm.province = $event), (_vm.town = ''), (_vm.townsKey = new Date())]}}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Towns'),"label-for":"town"}},[_c('BaseSelect',{key:("" + _vm.townsKey),attrs:{"resource":'/towns',"value":_vm.town,"httpBody":{ province_id: _vm.province.id },"label":'Municipio',"readonly":!_vm.province,"clearable":true},on:{"input":function($event){_vm.town = $event}}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Direccion')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Direccion'),"label-for":"client-address"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Direccion')},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CodigoPostal')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CodigoPostal'),"label-for":"client-postalCode"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('CodigoPostal')},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Guardar"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }